import { jsPDF } from "jspdf";
import template from "@/assets/templates/medical_reports/template";
import moment from "moment";

async function genMedicalReportJSPdfObj(examData) {
  let doc = new jsPDF("p", "mm", "a4");

  let reportObj = genReportObj(examData);

  let docContent = template(reportObj);

  await doc.html(docContent, {
    x: 10,
    y: 10,
  });

  return doc;
}

function genReportObj(examData) {
  let dateOfRealization = moment(examData.dateOfRealization)
    .add(3, "hours")
    .format("DD/MM/YYYY [ás] HH:mm");
  console.log(examData);

  let reportObj = {
    patient: {
      name: examData.Patient.Person.fullName,
      dateOfBirth: moment(examData.Patient.Person.dateOfBirth).format("DD/MM/YYYY"),
      age: Math.floor(moment().diff(examData.Patient.Person.dateOfBirth, "years", true)),
      gender: examData.Patient.Person.gender === "male" ? "Masculino" : "Feminino",
    },
    medicalReport: examData.MedicalReport.report,
    doctor: examData.MedicalReport.Doctor,
  };

  if (examData.type === "public") {
    let partnershipBranchName = examData.PartnershipBranch.name;
    let examName = examData.ExamReference.Procedure.name;

    let {
      companyName,
      telephone,
      cellphone,
      address,
      addressComplement,
      district,
      addressNumber,
      zipCode,
      City,
    } = examData.PartnershipBranch.Partnership.Person;

    reportObj.exam = {
      name: examName,
      id: examData.id,
      dateOfRealization,
      local: partnershipBranchName,
      requestedBy: examData.requestedBy,
      type: examData.type,
    };

    reportObj.header = {
      companyName,
      phone: telephone,
      cellphone,
      address: `${address},${addressNumber},${district},${addressComplement},${City.name}-${City.State.uf},${zipCode}`,
    };
  } else {
    let { Procedure, Scheduling } = examData.SchedulingProcedure;
    let examName = Procedure.name;
    let {
      companyName,
      phone,
      cellphone,
      address,
      addressComplement,
      district,
      addressNumber,
      zipCode,
      City,
    } = Scheduling.CompanyBranch;

    reportObj.exam = {
      name: examName,
      id: examData.id,
      dateOfRealization,
      local: "",
      requestedBy: examData.requestedBy,
      type: examData.type,
    };

    reportObj.header = {
      companyName,
      phone,
      cellphone,
      address: `${address},${addressNumber},${district},${addressComplement},${City.name}-${City.State.uf},${zipCode}`,
      logoUrl: "https://multimagemra-static.nyc3.digitaloceanspaces.com/logo.png",
    };
  }

  return reportObj;
}

export default { genMedicalReportJSPdfObj };
