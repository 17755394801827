<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Exames</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn color="success" class="mr-2" @click="loadExams()">
                        <v-icon left>mdi-filter</v-icon> Filtrar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.examStatus === 'completed'"
              icon
              title="Ver exame"
              @click="genMedicalReportPDF(item.id)"
            >
              <v-icon> mdi-file-pdf </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams()"
      ></v-pagination>
    </div>

    <v-dialog v-model="loadingDialog" persistent max-width="290">
      <v-card>
        <div class="pa-5 d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import examsHelper from "@/helpers/exams";

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      resultsPerPage: 50,
      totalRows: 0,
      loadingDialog: false,
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dateOfRealization" },
        { text: "Status", value: "examStatusName", sortable: false },
        { text: "Modalidade", value: "examMode", sortable: false },
        { text: "Exame", value: "examName", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      filters: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    async genMedicalReportPDF(examId) {
      try {
        this.loadingDialog = true;
        const examData = await this.loadExam(examId);

        const medicalReportJSPdfObj = await examsHelper.genMedicalReportJSPdfObj(
          examData
        );

        medicalReportJSPdfObj.save("laudo.pdf");
        this.loadingDialog = false;
      } catch (error) {
        this.loadingDialog = false;
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadExam(examId) {
      const url = `/exams/${examId}`;

      const res = await this.$axios.get(url);

      return { ...res.data };
    },
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          page: currentPage,
          ...this.filters,
        };

        let url = `/exams`;

        const response = await this.$axios.get(url, { params });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    clearFilters() {
      this.filters = {
        startDate: null,
        endDate: null,
      };
      this.loadExams();
    },
    setResponseData(data) {
      const copyOfData = [...data];

      this.items = copyOfData.map((item) => {
        if (item.examStatus === "pending") item.examStatusName = "Pendente";
        if (item.examStatus === "requested-resend")
          item.examStatusName = "Aguardando novo laudo";
        if (item.examStatus === "completed") item.examStatusName = "Finalizado";

        if (item.type === "private")
          item.examName = item.SchedulingProcedure.Procedure.name;
        else if (item.type === "public")
          item.examName = item.ExamReference.Procedure.name;

        item.dateOfRealization = this.$moment(item.dateOfRealization).format(
          "DD/MM/YYYY [ás] HH:mm"
        );

        return item;
      });
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  created() {
    this.loadExams();
  },
  beforeDestroy() {},
};
</script>

<style></style>
