const { baseUrl } = require("../../../config/config.json");

export default ({ header, exam, patient, medicalReport, doctor }) => {
  console.log("DEBUGANDO LAUDO");
  console.log(exam);
  console.log(patient);
  console.log(medicalReport);
  console.log(doctor);

  const template = `
  <html>
  <head>
    <title>Laudo</title>
  </head>
  <body>
    <div style="width:470px;border:1px;font-size:10px;font-family: Arial, Helvetica, sans-serif;">
      <table style="width:100%; margin: 30px 0px; border-collapse: separate; border-spacing: 20px;">
        <tr>
          <td style="width:30%;display: ${exam.type === "public" ? "none" : "table-cell"}">
            <img
              src="${header.logoUrl}"
              style="height:auto;width:100%;"
              alt=""
            />
          </td>
          <td>
            <h3 style="padding: 5px;">${header.companyName}</h3>

            <p style="padding: 5px;">Fones: ${header.phone} | ${header.cellphone}</p>

            <p style="padding: 5px;">Endereço: ${header.address}</p>
          </td>
        </tr>
      </table>

      <p style="text-align:center;font-weight:bold;font-size:12px;">
        Dados do paciente
      </p>
      <table style="width:100%">
        <tr>
          <td>
            <span style="font-weight:bold;">Paciente:</span>
            ${patient.name}
          </td>
          <td style="text-align: right">
            <span style="font-weight:bold;">Exame:</span> ${exam.id}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Data de nascimento:</span>
            ${patient.dateOfBirth}
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Sexo:</span>
            ${patient.gender}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Idade:</span>
            ${patient.age} anos
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Solicitado por:</span> ${exam.requestedBy}
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight:bold;">Local:</span>
            ${exam.local}
          </td>
          <td style="text-align:right">
            <span style="font-weight:bold;">Data de realização:</span>
            ${exam.dateOfRealization}
          </td>
        </tr>
      </table>

      <div style="margin:30px 0px;"></div>
      <p style="text-align:center;font-weight:bold;font-size:12px;">
        ${exam.name}
      </p>
      <div style="margin:30px 0px;"></div>
      ${medicalReport}
      <div style="margin:30px 0px;"></div>
      <div style="text-align:center;">
        <img
          style="max-width: 150px;"
          src="${baseUrl}/doctors/${doctor.id}/signature/image"
          alt=""
        />
        ${doctor.signatureText}
      </div>
    </div>
  </body>
</html>
`;

  return convertUn(0.4, template);
};

function convertUn(factor, str) {
  let copyOfStr = `${str}`;

  copyOfStr = copyOfStr.replace(/:?:\s*\d*px/g, function(value) {
    let qtd = value.replace(/\D/gim, "");
    return `:${Math.round(factor * parseInt(qtd))}px`;
  });

  return copyOfStr;
}
